import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './era-card.css';

const EraSelector = (props) => {
  let history = useHistory();

  let backgroundStyle = {};
  if (props.Erastation === 'GOOM Radio') {
    backgroundStyle = { 
      backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/GoomRadio.jpg")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
      
    };
  } else if (props.Erastation === 'EdgeFM') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/edgefm.png")` };
  } else if (props.Erastation === 'MLR') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/MLR.jpg")` };
  } else if (props.Erastation === 'PPR') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/PPR.jpeg")` };
  } else if (props.Erastation === 'UFRN') {
    backgroundStyle = { backgroundImage: `url("https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/UFRN.jpg")` };
  }

  function handleClick() {
    history.push(`/era?station=${encodeURIComponent(props.Erastation)}`);
  }

  return (
    <div className='MobileBGCover'>
    <div className="era-card-container" style={backgroundStyle} onClick={handleClick}>
      
      <p>
        <span className="era-card-text2">
          {props.Erastation ? props.Erastation : 'Value'}
        </span>
      </p>
      
    </div>
    </div>
  );
}

EraSelector.defaultProps = {
  Erastation: undefined,
};

EraSelector.propTypes = {
  Erastation: PropTypes.string,
};

export default EraSelector;
