import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import './DTHRoof.css';

import { useAuth } from './Auth'
import PropTypes from 'prop-types';

const DTHROOF = (props) => {
  const history = useHistory();
  const { isSignedIn, logout } = useAuth();

  const handleProfileIconClick = () => {
    history.push('/user');
  };

  const handleLogoutClick = async () => {
    try {
      await logout();
      history.push('/signin');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleUserIconClick = () => {
    history.push('/user');
  };
  
  const [isRecording, setIsRecording] = useState(false);

  const handleVoiceIconClick = () => {
    alert('Coming Soon. You will be able to Voice Your Opinion OR Tell Joe to Shut up. Free Speech isnt slient and You shouldnt be either!');
  };

  return (
    <>
      <div className="dthroof-container">
        <div
          id="DTH_Voice_Icon"
          className="dthroof-container1"
          onClick={handleVoiceIconClick}
        >
          <svg
            viewBox="0 0 1024 1024"
            className="dthroof-icon"
            style={{ fill: isRecording ? 'red' : 'white' }}
          >
            <path d="M512 682.667c94.123 0 170.667-76.587 170.667-170.667v-256c0-94.123-76.544-170.667-170.667-170.667s-170.667 76.544-170.667 170.667v256c0 94.080 76.544 170.667 170.667 170.667z"></path>
            <path d="M810.667 512v-85.333c0-23.552-19.072-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333c0 117.632-95.701 213.333-213.333 213.333s-213.333-95.701-213.333-213.333v-85.333c0-23.552-19.072-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333c0 150.187 111.488 274.432 256 295.253v46.080h-128c-23.595 0-42.667 19.072-42.667 42.667s19.072 42.667 42.667 42.667h341.333c23.595 0 42.667-19.072 42.667-42.667s-19.072-42.667-42.667-42.667h-128v-46.080c144.512-20.821 256-145.067 256-295.253z"></path>
          </svg>
        </div>
        <div className="dthroof-container2">
          <span className="dthroof-text">{props.text}</span>
        </div>
        <div id="DTH_Profile_Icon" className="dthroof-container3" >
          {isSignedIn ? (
            <button style={{ color: 'white' }} onClick={handleUserIconClick}>Profile</button>
          ) : (
            <svg viewBox="0 0 1024 1024" className="dthroof-icon3" onClick={handleProfileIconClick}>
              <path d="M512 0c282.857 0 512 229.143 512 512 0 281.143-228 512-512 512-283.429 0-512-230.286-512-512 0-282.857 229.143-512 512-512zM865.714 772c53.143-73.143 85.143-162.857 85.143-260 0-241.714-197.143-438.857-438.857-438.857s-438.857 197.143-438.857 438.857c0 97.143 32 186.857 85.143 260 20.571-102.286 70.286-186.857 174.857-186.857 46.286 45.143 109.143 73.143 178.857 73.143s132.571-28 178.857-73.143c104.571 0 154.286 84.571 174.857 186.857zM731.429 402.286c0-121.143-98.286-219.429-219.429-219.429s-219.429 98.286-219.429 219.429 98.286 219.429 219.429 219.429 219.429-98.286 219.429-219.429z"></path>
            </svg>
          )}
        </div>
      </div>
    </>
  )
}

DTHROOF.defaultProps = {
  text: 'Why? Because We Can!',
}

DTHROOF.propTypes = {
  text: PropTypes.string,
}

export default DTHROOF;