      import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAudioPlayer } from './Context';
import { useHistory } from 'react-router-dom';
import { removeFavorite } from '../FavoritesHelpers'; 
import { fetchFavorites } from '../FavoritesHelpers';
import { useAuth } from '../components/Auth'; // import useAuth to get user context
const Playlist = (props) => {
  const [isFavorite, setIsFavorite] = useState(true);
  const { setAudioSrc, setAudioTitle, isLive, setIsLive, setIsActiveFav,isPlaying,
          setIsPlaying,duration, setDuration } = useAudioPlayer();
  const history = useHistory();
  const { user } = useAuth(); // Get the signed-in user from Auth context
  const episodeDetailHandler = () => {
    const { title } = props;
    history.push(`/episode?title=${title}`);
  };
  useEffect(() => {
    if (!isFavorite && user && user.uid) {
      // Remove the favorite entry when isFavorite turns false
      removeFavorite(user.uid, props.id);
    }
  }, [isFavorite, user, props.id]);
  const FavoritesEpisodeHandlerAudio = () => {
    if (!isLive) {
      setIsLive(false);
    }
    setAudioTitle(props.title);
    setAudioSrc(props.EpisodeUrl);
    setDuration(props.EpisodeDuration);
    setIsActiveFav(true);
  };
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
    
  };
  return (
    <>
      <div className={`playlist-container ${props.rootClassName}`}>
        <div className="playlist-container1" onClick={FavoritesEpisodeHandlerAudio}>
          <svg viewBox="0 0 1024 1024" className="playlist-icon">
            <path d="M342 214l468 298-468 298v-596z"></path>
          </svg>
        </div>
        <div className="playlist-container2">
          <span className="playlist-text" onClick={episodeDetailHandler}>
            {props.title} | Played: {props.EpisodeDuration}
          </span>
          <svg
            viewBox="0 0 1024 1024"
            id="DTHFavoritesActive"
            className="playlist-icon2"
            fill={isFavorite ? '#FF0000' : 'none'}
            stroke="#FF0000"
            strokeWidth="50"
            onClick={toggleFavorite} // Add onClick handler to toggle favorite
          >
            <path d="M512 950.857c-9.143 0-18.286-3.429-25.143-10.286l-356.571-344c-4.571-4-130.286-118.857-130.286-256 0-167.429 102.286-267.429 273.143-267.429 100 0 193.714 78.857 238.857 123.429 45.143-44.571 138.857-123.429 238.857-123.429 170.857 0 273.143 100 273.143 267.429 0 137.143-125.714 252-130.857 257.143l-356 342.857c-6.857 6.857-16 10.286-25.143 10.286z"></path>
          </svg>
        </div>
      </div>
      <style jsx>
        {`
          .playlist-container1 {
  width: 100%;
  height: auto;
  display: flex;
  padding: -;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(210, 210, 210, 0);
}
.playlist-container2 {
  flex: 0 0 auto;
  width: 100px;
  height: 57px;
  display: flex;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  justify-content: center;
  background-color: #a8a8a8;
}
.playlist-icon1 {
  fill: #000000;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  padding: var(--dl-space-space-halfunit);
}
.playlist-container3 {
  flex: 0 0 auto;
  width: 90%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  justify-content: center;
  background-color: #000000;
}
.playlist-text {
  color: rgb(255, 249, 249);
  margin: var(--dl-space-space-halfunit);
  padding: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
}
.playlist-icon3 {
  fill: #D9D9D9;
  right: 0px;
  width: 24px;
  bottom: 14px;
  height: 24px;
  padding: 0px;
  position: absolute;
  margin-top: HalfU0nit;
  margin-left: HalfU0nit;
  margin-right: var(--dl-space-space-unit);
  margin-bottom: HalfU0nit;
}


@media(max-width: 991px) {
  .playlist-container1 {
    width: 100%;
    height: auto;
    margin: var(--dl-space-space-halfunit);
    padding: var(--dl-space-space-unit);
  }
  .playlist-container2 {
    width: 100px;
    height: 52px;
    padding-top: 0px;
  }
  .playlist-icon1 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    padding: var(--dl-space-space-halfunit);
  }
  .playlist-container3 {
    flex: 1;
    background-color: #000000;
  }
  .playlistroot-class-name1 {
    margin: 0px;
    align-self: center;
  }
}
@media(max-width: 420px) {
  .playlist-container1 {
    height: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .playlist-container2 {
    width: 98px;
    height: 54px;
  }
  .playlist-container3 {
    width: 75%;
  }
  .playlist-text {
    font-style: normal;
    text-align: center;
    font-weight: 700;
  }
}
        `}
      </style>
    </>
  )
}

Playlist.defaultProps = {
  title: 'The Ham Radio Show: Why? Because We can!',
  imageAlt: 'image',
  imageSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  text1: 'Text',
  text2: 'Text',
  rootClassName: '',
  EpisodeDuration: '0',
  EpisodeUrl: null,
  key: null,
}

Playlist.propTypes = {
  text: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Playlist
