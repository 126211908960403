import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import DTHCrewHeader from '../components/CrewHeader';
import { useAudioPlayer } from '../components/Context';

const CrewProfilePage = (props) => {
  const { audioTitle } = useAudioPlayer()
  const [crewData, setCrewData] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const crewName = params.get('name');

  useEffect(() => {
    if (crewName) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/CrewBios?Name=plfts.${encodeURIComponent(crewName)}`, {
            headers: {
              apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI'
            }
          });
          setCrewData(response.data[0]); // Assuming response.data is an array with a single object
        } catch (error) {
          console.error('Error fetching crew data:', error);
        }
      };

      fetchData();
    }
  }, [crewName]);

  if (!crewData) {
    return <div>Loading...</div>; // Loading state while data is being fetched
  }

  return (
    <>
      <div className="crew-profile-page-container">
        <Helmet>
          <title>{audioTitle ? audioTitle : `Crew - ${crewName}`}</title>
          <meta property="og:title" content={`${crewName} - Nick Test`} />
          <meta property="og:description" content="#DemandTheHAM!" />
          <meta
            property="og:image"
            content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
          />
        </Helmet>
        <div id="WhiteSpace" className="crew-profile-page-container1"></div>
        <DTHCrewHeader
          crewName={crewData?.Name}
          bgImageSrc={crewData?.HeaderBGImage}
          rootClassName="dth-crew-header-root-class-name"
          tpImageSrc={crewData.ProfileImg}
        />
        <div
          id="DTH_Crew_bio_Container"
          className="crew-profile-page-container3"
          style={{ padding: '8px', height: 'auto' }}
        >
          <span className="crew-profile-page-text">
            {crewData?.Bio || 'Loading...'}
          </span>
        </div>
    
      </div>
      <style jsx>
        {`
          .crew-profile-page-container {
            width: 100%;
            display: flex;
            overflow: auto;
            min-height: 100vh;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
          }
          .crew-profile-page-container1 {
            flex: 0 0 auto;
            width: 100%;
            /* border: 2px dashed rgba(120, 120, 120, 0.4); */
            height: 60px;
            display: flex;
            align-items: flex-start;
          }
          .crew-profile-page-container2 {
            flex: 1;
            width: 95%;
            height: 100px;
            margin: var(--dl-space-space-unit);
            display: flex;
            align-self: center;
            align-items: center;
            flex-direction: row;
            justify-content: center;
          }
          .crew-profile-page-icon {
            width: var(--dl-size-size-small);
            height: var(--dl-size-size-small);
            margin-right: var(--dl-space-space-halfunit);
          }
          .crew-profile-page-icon2 {
            width: var(--dl-size-size-small);
            height: var(--dl-size-size-small);
          }
          .crew-profile-page-container3 {
            flex: 0 0 auto;
            width: 95%;
            height: 100px;
            display: flex;
            padding: var(--dl-space-space-twounits);
            margin-top: var(--dl-space-space-halfunit);
            align-items: center;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: hidden;
            border-width: 2px;
            justify-content: flex-start;
            background-color: #000000;
          }
          .crew-profile-page-text {
            color: #ffffff;
            text-align: center;
            padding: 
          }
          @media (max-width: 991px) {
            .crew-profile-page-container2 {
              margin: var(--dl-space-space-unit);
            }
            .crew-profile-page-container3 {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default CrewProfilePage;