import React, { useState, useRef, useEffect } from 'react';
import { useAudioPlayer } from './Context';
import { useAuth } from './Auth';
import { database } from '../firebase';
import { ref, set } from 'firebase/database';
import MoreDrawer from './more-drawer';
import { useHistory } from 'react-router-dom';






const MobileControls = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isPlaying,
    setIsPlaying,
    audioSrc,
    setAudioSrc,
    audioTitle,
    setAudioTitle,
    audioImg,
    setAudioImg,
    isLive,
    setIsLive,
    duration,
    setDuration,
    TPBGImage,
    SetTPBGImage,
    BGImage,
    SetBGImage,
    audioSubtext,
    setAudioSubtext,
    isFav,
    setIsFav,
    isCasting,
    SetisCasting
  } = useAudioPlayer();

  const audioRef = useRef(null);
  const { user } = useAuth();
  const [currentTime, setCurrentTime] = useState(0);
  const history = useHistory();
  const [previousAudioSrc, setPreviousAudioSrc] = useState(audioSrc);


  // Effect to handle audio source changes for casting
  const cjs = new Castjs();

  const toggleModal = () => {
      setIsModalOpen(prev => !prev); // Toggles the modal state
  };

  const changeAudioSource = (newAudioSrc) => {
    setPreviousAudioSrc(newAudioSrc);
    setAudioSrc(newAudioSrc);

    if (audioRef.current) {
      audioRef.current.currentTime = 0; // Reset to 0 when source changes
      audioRef.current.src = newAudioSrc;
    }
  };

 useEffect(() => {
    if (audioSrc && audioRef.current) {
        // Check if the source has changed
        if (audioRef.current.src !== audioSrc) {
            console.log("Audio source changed. Updating src and resetting playback.");

            // Pause the audio, set the new source, and reset playback position
            audioRef.current.pause();
            audioRef.current.src = audioSrc;
            audioRef.current.currentTime = 0; // Reset playback position for the new source

            // Start playback if isPlaying is true
            if (isPlaying) {
                audioRef.current
                    .play()
                    .then(() => console.log("Playback started after source change."))
                    .catch((error) => console.error("Error playing audio after source change:", error));
            }
        } else {
            console.log("Audio source has not changed. Managing play/pause state.");

            // If the source hasn't changed, manage play/pause state
            if (isPlaying) {
                // Resume playback
                audioRef.current
                    .play()
                    .then(() => console.log("Resumed playback. Current Time:", audioRef.current.currentTime))
                    .catch((error) => console.error("Error resuming playback:", error));
            } else {
                // Pause playback
                audioRef.current.pause();
                console.log("Playback paused. Current Time:", audioRef.current.currentTime);
            }
        }
    }
}, [audioSrc, isPlaying]);


  const castPlayPauseHandler = () => {
    if (isCasting) {
      if (isPlaying) {
        cjs.pause();
      } else {
        cjs.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const casthandleSeek = (seekTime) => {
    if (cjs.connected) {
      cjs.seek(seekTime);
    }
  };

  const startCasting = (audioSrc, audioTitle, BGImage) => {
    if (!cjs.connected) {
      cjs.cast(audioSrc, {
        title: audioTitle,
        poster: BGImage ? BGImage : './gahamradio-800w.png',
        description: 'DemandTheHAM',
      });

      cjs.on('connected', () => {
        SetisCasting(true);
      });

      cjs.on('update', (state) => {
        // Add code to update your UI based on state
      });
    }
  };

  const castchangeAudioSourceWhileCasting = (audioSrc) => {
    if (cjs.connected) {
      cjs.disconnect();
      cjs.on('disconnected', () => {
        startCasting(audioSrc);
      });
    } else {
      startCasting(audioSrc);
    }
  };

  const casthandleDisconnect = (SetisCasting) => {
    if (cjs.connected) {
      cjs.disconnect();
      SetisCasting(false);
    }
  };

  useEffect(() => {
    if (isCasting && audioRef.current) {
      audioRef.current.muted = true;
    } 
    if (!isCasting && audioRef.current) {
      audioRef.current.muted = false;
    }
  }, [isCasting]);
  // Sync audio properties to database
  useEffect(() => {
    if (user) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      const audioSyncData = {
        AudioTitle: audioTitle || '',
        Duration: duration || 0,
        AudioImg: audioImg || '',
        AudioSrc: audioSrc || '',
        currentTime: currentTime || 0,
      };
      const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
      set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  }, [user, audioTitle, duration, audioImg, audioSrc, currentTime]);

  
  let savedTime = 0; // Add this to hold the saved time
  const handlePlayPauseAndSourceChange = () => {
  if (audioRef.current) {
    if (isPlaying) {
      // If currently playing, pause the audio
      audioRef.current.pause();
      console.log(`Playback paused. Current Time: ${audioRef.current.currentTime}`);
    } else {
      // If currently paused, play the audio
      audioRef.current.play()
        .then(() => {
          console.log(`Playback started. Current Time: ${audioRef.current.currentTime}`);
          syncAudioWithFirebase(); // Sync the playback state with Firebase
        })
        .catch(error => {
          console.error("Playback failed", error);
        });
    }
    
    // Toggle the play state
    setIsPlaying(prevIsPlaying => !prevIsPlaying);
  }
};

const syncAudioWithFirebase = () => {
    if (user && audioRef.current) {
        const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
        const audioSyncData = {
            AudioTitle: audioTitle || '',
            Duration: duration || 0,
            AudioImg: audioImg || '',
            AudioSrc: audioSrc || '',
            currentTime: audioRef.current.currentTime || 0,
        };

        // Sanitize the audioSyncData
        const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);

        set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
            console.error("Error updating Firebase:", error);
        });
    }
};

  useEffect(() => {
      if (audioRef.current) {
          console.log(
              `PlayPause clicked. Current Time: ${audioRef.current.currentTime}, Is Playing: ${isPlaying}, AudioSrc: ${audioSrc}`
          );
      }
  }, [isPlaying, audioSrc]);


  const handleSeek = (e) => {
    const seekTime = parseFloat(e.target.value);
    setCurrentTime(seekTime);
    if (audioRef.current) {
      audioRef.current.currentTime = seekTime;
    }
    if (user && !isPlaying) {
      const userAudioSyncRef = ref(database, `users/${user.uid}/ActiveAudioSync`);
      const audioSyncData = {
        AudioTitle: audioTitle,
        Duration: duration,
        AudioImg: audioImg,
        AudioSrc: audioSrc,
        currentTime: seekTime,
      };
      const sanitizedAudioSyncData = removeUndefinedProperties(audioSyncData);
      set(userAudioSyncRef, sanitizedAudioSyncData).catch((error) => {
        console.error("Error updating ActiveAudioSync:", error);
      });
    }
  };

  const handleLoadedData = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  // Time update listener
  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(audioRef.current.currentTime);
    };
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('loadeddata', handleLoadedData);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('loadeddata', handleLoadedData);
      }
    };
  }, []);

  const LogoOnClick = () => {
    history.push('/');
  };

  const handleContainer5Click = () => {
    setIsModalOpen(true);
  };

  const handleModalOutsideClick = (event) => {
    if (event.target.classList.contains('modal-container')) {
      setIsModalOpen(false);
    }
  };

  const LiveRadioHandler = () => {
    setIsLive(true);
    setAudioSrc("https://das-edge09-live365-dal03.cdnstream.com/a93581");
    setAudioTitle("The Ham Radio Show");
    if (audioRef.current) {
      audioRef.current.play();
    }
  };



  
  
  // Remove any undefined properties from the object
  const removeUndefinedProperties = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] !== undefined) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };

  return (
    <>
      <div className="mobile-controls-container">
        {isPlaying ? (
          <div className="mobile-controls-container1" onClick={handlePlayPauseAndSourceChange}>
            <svg viewBox="0 0 1024 1024" className="mobile-controls-icon">
              <path d="M768 512L256 512 256 576 768 576 768 512z"></path>
            </svg>
          </div>
        ) : (
          <div className="mobile-controls-container1" onClick={handlePlayPauseAndSourceChange}>
            <svg viewBox="0 0 1024 1024" className="mobile-controls-icon">
              <path d="M426 704l256-192-256-192v384zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
            </svg>
          </div>
        )}
        <div className="mobile-controls-container2">
          <span className="component2-text">{audioTitle}</span>
          <span className="component2-text1">{audioSubtext}</span>
          <audio src={audioSrc} ref={audioRef} hidden></audio>
          <input
            className="seekbar"
            type="range"
            id="seekbar"
            min="0"
            max={duration}
            value={currentTime}
            onChange={isCasting ? casthandleSeek : handleSeek}
          />
        </div>
        <div className="mobile-controls-container3" onClick={toggleModal}>
          <svg viewBox="0 0 24 6" className="mobile-controls-icon2" width="10" height="10">
            <circle cx="3" cy="3" r="3"></circle>
            <circle cx="12" cy="3" r="3"></circle>
            <circle cx="21" cy="3" r="3"></circle>
          </svg>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-container" onClick={(e) => e.target.classList.contains('modal-container') && setIsModalOpen(false)}>
          <div className="modal-content">
            <MoreDrawer />
            <button onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}

      <style jsx>
        {`
          .modal-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1001;
          }
          .modal-content {
            background: white;
            border-radius: 4px;
            padding: 20px;
          }
          .mobile-controls-container {
            width: 100%;
            height: 400px;
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 999;
          }
          .seekbar {
            width: 100%;
            height: 12px;
            margin: auto;
            padding: auto;
          }
          .mobile-controls-container1 {
            flex: 0 0 auto;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mobile-controls-icon {
            width: 16px;
            height:16px;
          }
          .mobile-controls-container2 {
            flex: 0 0 auto;
            width: 100%;
            border: 2px dashed rgba(120, 120, 120, 0.4);
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mobile-controls-container3 {
            flex: 0 0 auto;
            width: 50%;
            height: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mobile-controls-icon2 {
            width: 24px;
            height: 24px;
          }
          @media (max-width: 420px) {
            .mobile-controls-container {
              width: 100%;
              height: 102px;
              align-items: center;
              flex-direction: row;
              justify-content: center;
              background-color: #000000;
            }
            .mobile-controls-container1 {
              width: 15%;
            }
            .mobile-controls-icon {
              fill: #d9d9d9;
              width: 100%;
              height: 100%;
            }
            .mobile-controls-container2 {
              width: 60%;
              height: 100%;
              border-width: 0px;
            }
            .mobile-controls-container3 {
              width: 15%;
            }
            .mobile-controls-icon2 {
              fill: #d9d9d9;
              width: 100%;
              height: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

export default MobileControls;