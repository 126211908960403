/// src/FavoritesHelpers.js

import { getDatabase, ref, push, set, remove, child, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';

// Assuming the user is already logged in
const auth = getAuth();

// Get the current user's UID
export const userUid = auth.currentUser ? auth.currentUser.uid : null;

// Function to add a favorite item
export const addFavorite = async (userUid, audioSrc, audioTitle, isFav) => {
  const favoritesRef = ref(getDatabase(), `users/${userUid}/favorites`);
  const favoriteData = { audioSrc, audioTitle, isFav };
  try {
    const newFavoriteRef = push(favoritesRef);
    await set(newFavoriteRef, favoriteData);
    console.log("Favorite added successfully!");
    return newFavoriteRef.key;
  } catch (error) {
    console.error("Error adding favorite:", error);
  }
};

// Function to remove a favorite item
export const removeFavorite = async (userUid, favoriteId) => {
  const favoritesRef = ref(getDatabase(), `users/${userUid}/favorites/${favoriteId}`);
  try {
    await remove(favoritesRef);
    console.log("Favorite removed successfully!");
  } catch (error) {
    console.error("Error removing favorite:", error);
  }
};

// Function to fetch all favorite items
export const fetchFavorites = async (userUid) => {
  const dbRef = ref(getDatabase());
  try {
    const snapshot = await get(child(dbRef, `users/${userUid}/favorites`));
    if (snapshot.exists()) {
      const data = snapshot.val();
      return Object.keys(data).map(key => ({
        id: key,
        ...data[key]
      }));
    } else {
      console.log("No data available");
      return [];
    }
  } catch (error) {
    console.error("Error fetching favorites:", error);
    return [];
  }
};