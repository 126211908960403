import React, { useContext, useEffect,useState } from 'react';
import { useAudioPlayer } from '../components/Context';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import './episode-details-page.css';



const EpisodeDetails = (props) => {
  

  const cjs = new Castjs();

  const {isPlaying,
          setIsPlaying,
        audioTitle,
          setAudioTitle,setAudioSrc}=useAudioPlayer();
  

  const [data, setData] = useState({});

    const { handleOnClick, isLive,
            setIsLive, } = useAudioPlayer();
  const handleClick = () => {
        // Assuming the first item in the data array contains the audio URL
        if (data.length > 0) {
            const episode = data[0];
            handleOnClick(episode.EpisodeUrl, episode.title); // Call handleOnClick to play the audio
        }
    };

    useEffect(() => {
    const title = new URLSearchParams(window.location.search).get('title');
    const encodedTitle = encodeURIComponent(title);

    axios.get(`https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/Episodes?title=plfts.${encodedTitle}`, {
      headers: {
        'apiKey': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI',
        'x-DTH-Platform-Id': 'WebApp',
        'x-Request-Type':'Episode-Display',
      }
    })
      .then(response => {
        setData(response.data);
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);


  const DownloadEpisode = () => {
  const handleDownload = () => {
    const url = data[0]?.EpisodeUrl;
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      const fileName = url.split('/').pop() || 'audio.mp3'; // Extract the filename or set a default
      link.setAttribute('download', fileName); // Use the extracted fileName or default
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  }
    
  const startCasting = () => {
    setAudioSrc(data[0]?.EpisodeUrl);
    setAudioTitle(data[0]?.title);

    if (!cjs.connected) {
      cjs.cast(data[0]?.EpisodeUrl, {
        title: data[0]?.title,
        poster: data[0]?.PodcastImage ? data[0].PodcastImage : './gahamradio-800w.png',
        description: 'DemandTheHAM',
      });
    }
  };
	const handleShareCastClick = () => {
		alert("Coming Soon");
	};
  
  return (
    <div className="episode-details-container">
      <Helmet>
        <title>{audioTitle ? audioTitle : "DemandTheHam - The Ham Radio Show"}</title>
        <meta property="og:title" content="Episode-Details - Nick Test" />
        <meta property="og:description" content="#DemandTheHAM!" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/c8a5e376-1280-4be7-b0d4-c60a65d55836/44b6fe29-9b32-4a55-a492-d8ad2dcd12ae?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="episode-details-container1"></div>
      <div className="episode-details-container2" style={{
          backgroundImage: `url(${data.length > 0 && data[0]?.PodcastImage ? data[0].PodcastImage : 'https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/DTHCrew/HAM%20Radio%20GOOM%20STUDIO.png'})`,
        }}>
      
        {isPlaying && audioTitle === (data.length > 0 && data[0]?.title) ? (
          <svg viewBox="0 0 1024 1024" className="episode-details-icon" onClick={handleClick}>
            <path d="M768 512L256 512 256 576 768 576 768 512z"></path>
          </svg>
        ) : (
          <svg viewBox="0 0 1024 1024" className="episode-details-icon" onClick={handleClick}>
            <path d="M426 704l256-192-256-192v384zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
          </svg>
        )}
        <div className="episode-details-container3">
          <span id="MobileTitle" className="episode-details-text">
            {data.length > 0 && data[0]?.title}
          </span>
          <span id="EpisodeEraSubtitle" className="episode-details-text1">
            <span> {data.length > 0 && data[0]?.subtext || 'The Most Dangerous Radio Today...and perhaps even tomorrow'}</span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="episode-details-container4">
        <span id="Epi-Detail-Descript" className="episode-details-text4">
          {data.length > 0 && data[0]?.description} 
        </span>
      </div>

     
      <div className="episode-details-container5">
        <svg viewBox="0 0 1024 1024" className="episode-details-icon2" id="Cast" onClick={startCasting}>
          <path d="M42 426q194 0 332 138t138 332h-86q0-160-113-272t-271-112v-86zM42 598q124 0 212 87t88 211h-86q0-88-63-151t-151-63v-84zM42 768q52 0 90 38t38 90h-128v-128zM896 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-298v-86h298v-596h-768v128h-86v-128q0-34 26-60t60-26h768z"></path>
        </svg>
        <button className="episode-details-button" id="Download" onClick={() => {
          const url = data[0]?.EpisodeUrl;
          if (url) {
            const link = document.createElement('a');
            link.href = url;
            const fileName = url.replace('https://dthepi.nyc3.cdn.digitaloceanspaces.com/episodes/', '');
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }}>
          Download
        </button>
      </div>
       <div class="sharethis-inline-share-buttons"></div>

      
    </div>
  )
}

export default EpisodeDetails
