import React, { useState, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Playlist from '../components/Playlist';
import { fetchFavorites } from '../FavoritesHelpers';
import { useAuth } from '../components/Auth'; 
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const PlaylistPage = (props) => {
  const { user, logout } = useAuth(); // Get the signed-in user and logout function from Auth context
  const [favorites, setFavorites] = useState([]);
  const history = useHistory(); // Correctly declare history here

  useEffect(() => {
    if (user && user.uid) {
      const fetchData = async () => {
        const favoritesArray = await fetchFavorites(user.uid);
        setFavorites(favoritesArray);
      };
      fetchData();
    }
  }, [user]);

  const handleLogoutClick = async () => {
    try {
      await logout();
      history.push('/'); // Redirect to the root endpoint after logout
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      <div className="playlist-page-container">
        <Helmet>
          <title>Favorites: {user ? user.uid : 'DemandTheHam Profile'}</title>
        </Helmet>
        <div className="playlist-selection-container1">
          <div className="playlist-selection-container2">
            <div className="playlist-selection-container3">
              <h1>
                {props.heading ?? (
                  <Fragment>
                    <h1 className="playlist-selection-text5">
                      H.O.F: {user ? user.uid : 'Ham Radio Show Official Fan'} 
                    </h1>
                  </Fragment>
                )}
              </h1>
            </div>
            <div className="playlist-selection-container4">
              <button 
                type="button" 
                className="playlist-selection-button button"
                onClick={handleLogoutClick} // Attach logout functionality
              >
                <span>
                  {props.button1 ?? (
                    <Fragment>
                      <span className="playlist-selection-text4">Log Out</span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </div>
          <div className="playlist-selection-container5">
            <span>
              {props.text ?? (
                <Fragment>
                  <span className="playlist-selection-text6">My Favorites</span>
                </Fragment>
              )}
            </span>
          </div>
        </div>

        {favorites.map(favorite => (
          <div key={favorite.id}>
            <Playlist
              id={favorite.id}
              title={favorite.Title}
              EpisodeDuration={favorite.Duration}
              EpisodeUrl={favorite.Episodeurl}
              rootClassName="playlistroot-class-name1"
            />
          </div>
        ))}
      </div>
      <style jsx>
        {`
          /* Combine styles from both components */
          .playlist-page-container {
            width: 100%;
            display: flex;
            overflow: auto;
            min-height: 100vh;
            text-align: center;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            background-color: #ffffff;
          }
          .playlist-selection-container1, .playlist-selection-container2, .playlist-selection-container3, .playlist-selection-container4, .playlist-selection-container5 {
            margin: 10px;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
          }
          .playlist-selection-text4 {
            color: #333;
          }
          .playlist-selection-text5, .playlist-selection-text6 {
            font-weight: bold;
          }
        `}
      </style>
    </>
  );
};

PlaylistPage.defaultProps = {
  button1: undefined,
  heading: undefined,
  text: undefined,
};

PlaylistPage.propTypes = {
  button1: PropTypes.element,
  heading: PropTypes.element,
  text: PropTypes.element,
};

export default PlaylistPage;