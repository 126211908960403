import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const NewsCard = (props) => {
  const history = useHistory();

   const handleCardClick = () => {
    if (props.NewsType === "wtfnews") {
      history.push(`/WTFNews/${props.NewsSlug}`);
    } else if (props.NewsType === "dth_news") {
      history.push(`/News/${props.NewsSlug}`);
    } else if (props.NewsType === "spnkbnk") {
      history.push(`/SpankBank/Post/${props.NewsSlug}`);
    }
  };

  
  return (
    <>
      <div className="news-card-container" onClick={handleCardClick} style={{ backgroundImage: props.BGImage || "https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg" }}>

        <span className="news-card-text">{props.Title.length > 15 ? `${props.Title.slice(0,15)}...` : props.Title}</span>
        <span className="news-card-text1">{props.Subtext.length > 15 ? `${props.Subtext.slice(0,15)}...` : props.Subtext}</span>
        <span className='news-card-text1'> {props.catagory}</span>
        <svg viewBox="0 0 1170.2857142857142 1024" className="news-card-icon">
          <path d="M585.143 292.571h-219.429v219.429h219.429v-219.429zM658.286 658.286v73.143h-365.714v-73.143h365.714zM658.286 219.429v365.714h-365.714v-365.714h365.714zM1024 658.286v73.143h-292.571v-73.143h292.571zM1024 512v73.143h-292.571v-73.143h292.571zM1024 365.714v73.143h-292.571v-73.143h292.571zM1024 219.429v73.143h-292.571v-73.143h292.571zM146.286 768v-548.571h-73.143v548.571c0 20 16.571 36.571 36.571 36.571s36.571-16.571 36.571-36.571zM1097.143 768v-621.714h-877.714v621.714c0 12.571-2.286 25.143-6.286 36.571h847.429c20 0 36.571-16.571 36.571-36.571zM1170.286 73.143v694.857c0 60.571-49.143 109.714-109.714 109.714h-950.857c-60.571 0-109.714-49.143-109.714-109.714v-621.714h146.286v-73.143h1024z"></path>
        </svg>
      </div>
      <style jsx>
        {`
          .news-card-container {
            width: 200px;
            height: 200px;
            display: flex;
            position: relative;
            align-items: flex-start;
            flex-direction: column;
            background-size: cover;
            justify-content: flex-start;
            background-image: url('https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/HAMFallBack.jpg');
            background-repeat: no-repeat;
            background-position: center;
          }
          .news-card-text {
            color: #ffffff;
            margin: var(--dl-space-space-unit);
            padding: var(--dl-space-space-halfunit);
            font-size: 16px;
            align-self: flex-start;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.86);
          }
          .news-card-text1 {
            margin: var(--dl-space-space-halfunit);
            background-color: rgba(217, 217, 217, 0.77);
            font-size: 14px;
          }
          .news-card-icon {
            fill: #d9d9d9;
            right: 0px;
            width: 33px;
            bottom: 0px;
            height: 33px;
            margin: var(--dl-space-space-halfunit);
            position: absolute;
          }
          @media (max-width: 991px) {
            .news-card-container {
              border-radius: var(--dl-radius-radius-radius4);
              border-top-left-radius: var(--dl-radius-radius-radius8);
            }
            .news-card-text1 {
              margin: var(--dl-space-space-halfunit);
              padding: 4px;
              font-size: 12px;
              background-color: #ffffff;
            }
            .news-card-icon {
              right: 0px;
              bottom: 0px;
              position: absolute;
              align-self: flex-end;
            }
          }
          @media (max-width: 420px) {
            .news-card-container {
              padding: var(--dl-space-space-halfunit);
              border-radius: var(--dl-radius-radius-radius4);
              border-top-left-radius: var(--dl-radius-radius-radius2);
              height:200px;
              width: 200px;
            }
          };
            }
          }
            }
          }

.eracontainer {
  display: flex; // Set the display to flex for horizontal layout
  flex-direction: row; // Default flex direction is row for horizontal layout
  // Include other necessary styling for .eracontainer
}
@media (max-width: 460px) {
  .eracontainer {
    flex-direction: column; // Set flex direction to column for vertical layout below 460px
  }
}
          
        `}
      </style>
    </>
  )
}

NewsCard.defaultProps = {
  Title: 'Nick Test',
  image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_alt: 'image',
  Subtext: 'The Test Article!',
  image_src1: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_alt1: 'image',
  PostID: '',
  BGImage: '',
  NewsType: '',
  NewsSlug: '',
}

NewsCard.propTypes = {
  Title: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  Subtext: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt1: PropTypes.string,
  PostID: PropTypes.string,
  BGImage: PropTypes.string,
  NewsType: PropTypes.string,
  NewsSlug: PropTypes.string,
}

export default NewsCard
