import React, { createContext, useState, useContext,useRef, useEffect } from 'react';


// Create the context
const AudioPlayerContext = createContext();

// Provide the context
const AudioPlayerProvider = ({ children }) => {
  const [audioSrc, setAudioSrc] = useState("https://dthepi.nyc3.cdn.digitaloceanspaces.com/episodes/44a6a6ac-e620-3fdf-acfa-3ded6c197fa6_503.mp3");
  const [audioTitle, setAudioTitle] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [TPBGImage, SetTPBGImage] = useState('');
  const [BGImage, SetBGImage] = useState('');
  const [audioSubtext, setAudioSubtext]= useState('');
  const [isActiveFav, setIsActiveFav] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [isCasting, SetisCasting] = useState(false);
   const audioRef = useRef(null); 


  const fetchLiveData = async () => {
    try {
      const response = await fetch("https://Services.Hamradioshow.com/api/Onair");
      const data = await response.json();
      if (data.audioTitle && data.audioSubtext) {
        setAudioTitle(data.audioTitle);
        setAudioSubtext(data.audioSubtext);
      }
    } catch (error) {
      console.error("Error fetching live data:", error);
    }
  };
  useEffect(() => {
    let intervalId;
    if (isLive) {
      fetchLiveData(); // Fetch immediately when isLive is true
      intervalId = setInterval(fetchLiveData, 120000); // Fetch every 2 minutes
    }
    return () => {
      clearInterval(intervalId); // Clear the interval on component unmount or when isLive changes
    };
  }, [isLive]);
  
  const changeAudioSource = (newAudioSrc) => {
        setAudioSrc(newAudioSrc);
        if (audioRef.current) {
            audioRef.current.currentTime = 0; // Reset time to 0 when changing the source
            audioRef.current.src = newAudioSrc;
        }
    };
    const handleOnClick = (url, title) => {
        if (isPlaying) {
            setIsPlaying(false); // Stop playing
        }
        // Use changeAudioSource to set the new audio source
        setTimeout(() => {
            changeAudioSource(url); // Use changeAudioSource instead of setAudioSrc
            // Assuming you have a setAudioTitle method defined
            setAudioTitle(title); // Set the audio title
            setIsPlaying(true); // Play the new audio automatically
        }, 100);
    };
  return (
    <AudioPlayerContext.Provider
      value={{
        audioSrc,
        changeAudioSource,
        setAudioSrc,
        audioTitle,
        setAudioTitle,
        isPlaying,
        setIsPlaying,
        isLive,
        setIsLive,
        duration,
        setDuration,
        currentTime,
        setCurrentTime,
        TPBGImage,
        SetTPBGImage,
        BGImage,
        SetBGImage,
        audioSubtext,
          setAudioSubtext,
        isActiveFav,
        setIsActiveFav,
        isFav,
          setIsFav,
        handleOnClick,
        isCasting, 
        SetisCasting,
        audioRef
        
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

// Custom hook to use the AudioPlayerContext
const useAudioPlayer = () => {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error('useAudioPlayer must be used within a AudioPlayerProvider');
  }
  console.log(context); // Inspect the state here
  return context;
};

export { AudioPlayerProvider, useAudioPlayer, AudioPlayerContext };