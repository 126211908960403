
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAudioPlayer } from '../components/Context';
import { useParams } from 'react-router-dom'; 
import fetch from 'node-fetch';
import './WTFNews.css';

const WTFNews = () => {
  const { slug } = useParams(); // Extracting SlugValue from the URL path
  const { audioTitle } = useAudioPlayer();
  const [newsDetails, setNewsDetails] = useState(null); // State to hold the fetched news details

  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const response = await fetch(`https://app.thehamradio.show/wp-json/wp/v2/posts?id=${slug}`); // Fetching post details using WordPress API
        const data = await response.json(); // Parse the JSON response
        setNewsDetails(data[0]); // Assuming the API returns an array, get the first item
      } catch (error) {
        console.error("Error fetching news details", error);
      }
    };

    fetchNewsDetails();
  }, [slug]); // Runs when slug changes

  if (!newsDetails) {
    return (
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        Loading...
      </div>
    ); // Show a loading state while fetching data
  }

  return (
    <div className="wtf-news-container">
      <Helmet>
        <title>{audioTitle ? audioTitle : "Direct From The Bunker - DemandTheHam"}</title>
        <meta property="og:title" content="Direct From The Bunker - DemandTheHam" />
        <meta property="og:description" content="#DemandTheHAM!" />
      </Helmet>

      { /* <img className='WTFNewsFeaturedImg' src={newsDetails.featured_media ? newsDetails._embedded['wp:featuredmedia'][0].source_url : ''}   alt={newsDetails.title.rendered} /> */} 
      <div className="wtf-news-header">
        <h1 dangerouslySetInnerHTML={{ __html: newsDetails.title.rendered }}></h1>
      </div>
      
      <div className="wtf-news-content">
        <div className="news-grid">
          <div dangerouslySetInnerHTML={{ __html: newsDetails.content.rendered }}></div>
        </div>
      </div>
    </div>
  );
};

WTFNews.propTypes = {
  NewsTitle: PropTypes.string,
  NewsContent: PropTypes.string,
};

export default WTFNews;