import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import DTHEpisodeCatalog from '../components/DTHPlayList-Item'


const EraPostSelelctionTemplate = ({ EraTitle, EraDescript }) => {

 let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const era = queryParams.get('station');
  const [episodes, setEpisodes] = useState([])
  useEffect(() => {
    // Step 2 & 3: Extract station parameter and make an API call
    const fetchEpisodes = async () => {
      const endpoint = `https://wizzvzmqudvvbgzkrhrt.supabase.co/rest/v1/Episodes?order=pubdate.desc&era=eq.${encodeURIComponent(era)}`;
      const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Indpenp2em1xdWR2dmJnemtyaHJ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg0OTkyNzIsImV4cCI6MjAwNDA3NTI3Mn0.MsWGlJzP0wyx4MrwTo5RZpzlSAxgypPG41V_w-cRnOI';
      try {
        const response = await axios.get(endpoint, {
          headers: {
            'apiKey': apiKey,
            'Content-Type': 'application/json',
          },
        });
         setEpisodes(response.data); // Set the episodes data
      } catch (error) {
        console.error(error);
      }
    };
    if (era) {
      fetchEpisodes();
    }
  }, [era]);
  
  const RssFeedClick = () => {
    window.open(`https://rss.hamradioshow.com?era=${era}`, '_blank');
  }
  
  return (
    <>
      <div className="era-post-selelction-template-container">
        <div className="era-post-selelction-template-container1">
          <div className="era-post-selelction-template-container2">
            <h1 className="era-post-selelction-template-text">
              <span>{era}</span> </h1>
              <br></br>
            <div className="era-post-selelction-template-container3" >
              <svg onClick={() => alert('Coming Soon!')}
                id="DTH_SELECTION_SHARE"
                viewBox="0 0 954.2948571428572 1024"
                className="era-post-selelction-template-icon"
              >
                <path d="M804.571 565.143v148c0 90.857-73.714 164.571-164.571 164.571h-475.429c-90.857 0-164.571-73.714-164.571-164.571v-475.429c0-90.857 73.714-164.571 164.571-164.571h145.714c9.714 0 18.286 8 18.286 18.286 0 9.143-6.286 16.571-14.857 18.286-28.571 9.714-54.286 21.143-76 34.286-2.857 1.143-5.714 2.286-9.143 2.286h-64c-50.286 0-91.429 41.143-91.429 91.429v475.429c0 50.286 41.143 91.429 91.429 91.429h475.429c50.286 0 91.429-41.143 91.429-91.429v-122.286c0-6.857 4-13.143 10.286-16.571 11.429-5.143 21.714-12.571 30.857-21.143 5.143-5.143 13.143-7.429 20-4.571s12 9.143 12 16.571zM940 281.714l-219.429 219.429c-6.857 7.429-16 10.857-25.714 10.857-4.571 0-9.714-1.143-14.286-2.857-13.143-5.714-22.286-18.857-22.286-33.714v-109.714h-91.429c-125.714 0-205.714 24-250.286 74.857-46.286 53.143-60 138.857-42.286 270.286 1.143 8-4 16-11.429 19.429-2.286 0.571-4.571 1.143-6.857 1.143-5.714 0-11.429-2.857-14.857-7.429-4-5.714-94.857-134.286-94.857-248.571 0-153.143 48-329.143 420.571-329.143h91.429v-109.714c0-14.857 9.143-28 22.286-33.714 4.571-1.714 9.714-2.857 14.286-2.857 9.714 0 18.857 4 25.714 10.857l219.429 219.429c14.286 14.286 14.286 37.143 0 51.429z"></path>
              </svg>
              <svg onClick={RssFeedClick}
                id="DTH_SELECTION_RSS"
                viewBox="0 0 1024 1024"
                className="era-post-selelction-template-icon2"
              >
                <path d="M170 430q176 0 300 124t124 300h-122q0-124-89-213t-213-89v-122zM170 190q274 0 469 195t195 469h-120q0-226-159-385t-385-159v-120zM170 760q0-38 27-65t67-27 66 26 26 66-27 67-65 27q-40 0-67-27t-27-67z"></path>
              </svg>
            </div>
          </div>
          <div className="era-post-selelction-template-container4">
            <img id="Era_Image"
              alt="image"
              style={{ height: '200px', width: '100%'}}
              src="https://dthepi.nyc3.cdn.digitaloceanspaces.com/Assets/hamYT.png"
              className="era-post-selelction-template-image"
            />
          </div>
        </div>
        <div className="era-post-selelction-template-container5"></div>
        <p
          id="DTH_ERA_Description"
          className="era-post-selelction-template-text3"
        >
      
            {era === 'GOOM Radio' && <span>The GOOM Radio era of The Ham Radio Show was like the wild west of airwaves, where the only law was "expect the unexpected." From 2009 to 2011, this show rode the evolution of radio with the grace of a bull in a china shop, leaving behind a trail of memorable, if not controversial, moments. One such incident involved Alexis Ford, which, much like a spicy jalapeño in a bland sandwich, added some serious kick to the show's reputation</span>}
            {era === 'MLR' && <span>MLR Content</span>}
            {era === 'PPR' && <span>PPR Content</span>}
            {era === 'UFRN' && <span>UFRN Content</span>}
          
        
        </p>
         {episodes.map((episode) => (
        <DTHEpisodeCatalog key={episode.id} title={episode.title} url={episode['EpisodeUrl']} />
      ))}
      </div>
      <style jsx>
        {`
          .era-post-selelction-template-container {
            width: 100%;
            display: flex;
            overflow: auto;
            min-height: 100vh;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
          }
          .era-post-selelction-template-container1 {
            flex: 0 0 auto;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
          }
          .era-post-selelction-template-container2 {
            flex: 0 0 auto;
            width: 746px;
            height: 144px;
            display: flex;
            align-items: center;
            border-radius: var(--dl-radius-radius-radius4);
            flex-direction: column;
            justify-content: center;
            border-top-right-radius: var(--dl-radius-radius-radius2);
            border-bottom-right-radius: var(--dl-radius-radius-round);
          }
          .era-post-selelction-template-text {
            color: #ffffff;
            margin: 0px;
            padding: var(--dl-space-space-halfunit);
            font-style: normal;
            font-weight: 800;
            line-height: 1.5;
            border-radius: var(--dl-radius-radius-radius4);
            background-color: #020202;
            border-top-left-radius: var(--dl-radius-radius-radius8);
            border-bottom-left-radius: var(--dl-radius-radius-radius8);
          }
          .era-post-selelction-template-container3 {
            flex: 0 0 auto;
            width: auto;
            height: 24px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
          }
          .era-post-selelction-template-icon {
            width: var(--dl-size-size-small);
            height: var(--dl-size-size-small);
            margin: var(--dl-space-space-unit);
          }
          .era-post-selelction-template-icon2 {
            width: var(--dl-size-size-small);
            height: var(--dl-size-size-small);
            margin: var(--dl-space-space-unit);
          }
          .era-post-selelction-template-container4 {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            
          }
          .era-post-selelction-template-image {
            height: auto;
            align-self: stretch;
            object-fit: cover;
            padding-right: 0px;
          }
          .era-post-selelction-template-container5 {
            flex: 0 0 auto;
            width: 100%;
            height: auto;
            display: flex;
            align-self: center;
            align-items: flex-start;
            border-color: rgba(120, 120, 120, 0.4);
            border-style: dashed;
            border-width: 0px;
            flex-direction: column;
          }
          .era-post-selelction-template-text3 {
            color: #ffffff;
            padding: var(--dl-space-space-halfunit);
            align-self: center;
            text-align: center;
            border-color: var(--dl-color-gray-black);
            border-width: 1px;
            background-color: #000000;
          }
          @media (max-width: 991px) {
            .era-post-selelction-template-container1 {
              align-self: center;
              border-color: var(--dl-color-gray-black);
              border-width: 0px;
              flex-direction: column;
            }
            .era-post-selelction-template-container2 {
              width: 100%;
              height: 100%;
              align-self: center;
              flex-direction: row;
            }
            .era-post-selelction-template-icon {
              width: 24px;
              height: 24px;
              margin: var(--dl-space-space-halfunit);
            }
            .era-post-selelction-template-icon2 {
              width: 24px;
              height: 24px;
              margin-top: var(--dl-space-space-halfunit);
              margin-left: var(--dl-space-space-halfunit);
              margin-right: var(--dl-space-space-halfunit);
              margin-bottom: var(--dl-space-space-halfunit);
              padding-bottom: 0px;
            }
            .era-post-selelction-template-image {
              display: none;
              align-self: center;
            }
            .era-post-selelction-template-container5 {
              border-width: 0px;
            }
            .era-post-selelction-template-text3 {
              color: #ffffff;
              background-color: #000000;
            }
          }
          @media (max-width: 550px) {
            .era-post-selelction-template-container1 {
              flex-direction: column;
              justify-content: flex-start;
            }
            .era-post-selelction-template-container2 {
              width: 100%;
              height: 100%;
            }
            .era-post-selelction-template-text {
              font-size: 24px;
            }
            .era-post-selelction-template-icon {
              margin: var(--dl-space-space-halfunit);
            }
            .era-post-selelction-template-icon2 {
              margin: var(--dl-space-space-halfunit);
            }
            .era-post-selelction-template-image {
              width: 100%;
              height: 100%;
            }
            .era-post-selelction-template-text3 {
              display: none;
            }
          }
        `}
      </style>
    </>
  )
}

export default EraPostSelelctionTemplate
